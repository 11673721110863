import { ref, onMounted } from "vue";

export default function useListPeripherals(peripheralsOnUse) {
  const camera = ref([]);
  const headphone = ref([]);
  const microphone = ref([]);

  function listPeripherals() {
    if (!navigator.mediaDevices?.enumerateDevices) {
      return;
    }

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        resetListPeripherals(headphone.value);
        resetListPeripherals(microphone.value);
        resetListPeripherals(camera.value);
        devices.forEach((device) => {
          const { deviceId, kind, label, groupId } = device;
          if (kind === "audioinput") {
            microphone.value.push({ label, value: deviceId, groupId });
          } else if (kind === "audiooutput") {
            headphone.value.push({ label, value: deviceId });
          } else if (kind === "videoinput") {
            camera.value.push({ label, value: deviceId });
          }
        });

        if (camera.value.length > 0) {
          peripheralsOnUse.camera = camera.value[0].value;
        }
        if (microphone.value.length > 0) {
          peripheralsOnUse.microphone = microphone.value[0].value;
        }
        if (headphone.value.length > 0) {
          peripheralsOnUse.headphone = headphone.value[0].value;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function resetListPeripherals(list) {
    while (list.length > 0) {
      list.pop();
    }
  }

  navigator.mediaDevices.ondevicechange = (event) => {
    listPeripherals();
  };

  onMounted(() => {
    listPeripherals();
  });

  return {
    headphone,
    microphone,
    camera,
  };
}
