<template>
  <q-btn
    class="q-px-md q-py-sm secundary-button width"
    color="primary"
    no-caps
    rounded
    :class="{ small: small, medium: medium, large: large, xLarge: xLarge }"
    outline
    @click="$emit('onClick')"
  >
    <template v-slot:default>
      <slot name="tooltip"></slot>
      <div class="row items-center justify-center no-wrap">
        <slot name="left-icon"></slot>
        <span class="label-btn" :style="{ color: colorLabel }">{{
          label
        }}</span>
        <slot name="right-icon"></slot>
      </div> </template
  ></q-btn>
</template>

<script>
export default {
  name: "SecundaryButton",
  props: {
    label: String,
    colorLabel: { type: String, default: "var(--q-primary,#fe4e64)" },
    width: {
      type: String,
      default: "fit-content",
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onClick"],

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.secundary-button.q-btn--outline:before {
  border: 1px solid var(--q-border-primary, #ccc);
}

.label-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.0156rem;
  white-space: nowrap;
}

:is(.large, .medium, .xLarge) {
  font-size: 16px;
}

.small {
  font-size: 14px;
  height: 36px;
}

.large {
  height: 48px;
}

.medium {
  height: 42px;
}

.xLarge {
  height: 56px;
}

.width {
  width: v-bind(width);
}
</style>
