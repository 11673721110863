<template>
  <q-btn
    color="default-pink"
    :icon="icon"
    @click="$emit('onClick')"
    round
    :outline="state"
  />
</template>

<script>
import { computed } from "vue";

export default {
  name: "OnAndOffButton",
  emits: ["onClick"],
  props: {
    iconOn: {
      type: String,
      required: true,
    },
    iconOff: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const icon = computed(() => {
      return props.state ? props.iconOn : props.iconOff;
    });

    return {
      icon,
    };
  },
};
</script>
