<template>
  <section class="container">
    <Mural
      :breadcrumbs="breadcrumbs"
      :query="{
        courseId: route.query.courseId,
        trainingId: route.query.trainingId,
        postId: route.query.trainingId,
      }"
      route="training-page"
    />
    <div class="container-items">
      <main class="preview-item">
        <div class="container-video">
          <video
            autoplay
            playsinline
            ref="previewCamera"
            v-show="currentMediaOnUse.camera"
          ></video>
          <div class="video-off" v-if="!currentMediaOnUse.camera">
            <Avatar :firstName="first_name" :lastName="last_name" />
            <p>Sua câmera está desligada</p>
          </div>
          <div class="container-on-off">
            <OnAndOffButton
              iconOn="mic"
              iconOff="mic_off"
              :state="currentMediaOnUse.microphone"
              @onClick="Microphone"
            />
            <OnAndOffButton
              iconOn="videocam"
              iconOff="videocam_off"
              :state="currentMediaOnUse.camera"
              @onClick="Camera"
            />
          </div>
          <p class="fullName" v-if="currentMediaOnUse.camera">
            {{ fullName }}
          </p>
          <p class="fullName fullName--on-off" v-if="!currentMediaOnUse.camera">
            {{ fullName }}
          </p>
        </div>
        <div class="enumerables-devices">
          <div class="device">
            <q-select
              outlined
              v-model="peripheralsOnUse.microphone"
              :options="peripherals.microphone"
              map-options
              emit-value
              @update:model-value="handleSelectionMicrophone"
            >
              <template v-slot:prepend>
                <q-icon name="mic" />
              </template>
            </q-select>
          </div>
          <div class="device">
            <q-select
              outlined
              emit-value
              map-options
              v-model="peripheralsOnUse.headphone"
              :options="peripherals.headphone"
              @update:model-value="handleSelectionHeadPhone"
            >
              <template v-slot:prepend>
                <q-icon name="volume_up" />
              </template>
            </q-select>
          </div>
          <div class="device">
            <q-select
              outlined
              emit-value
              map-options
              v-model="peripheralsOnUse.camera"
              :options="peripherals.camera"
              @update:model-value="handleSelectionCamera"
            >
              <template v-slot:prepend>
                <q-icon name="videocam" />
              </template>
            </q-select>
          </div>
        </div>
      </main>
      <div class="join-in" v-if="true">
        <h1 class="join-in-title">Pronto para participar?</h1>
        <!-- s -->
        <q-img :src="womanWithLaptop" width="208px" height="226px" />
        <div class="join-in-actions">
          <PrimaryButton
            large
            label="Entrar na aula"
            @onClick="join"
            width="184px"
          />
          <SecundaryButton
            large
            label="Encerrar aula"
            @onClick="dialogsState.shutUpLiveClass = true"
            width="184px"
            v-if="canEndLesson"
          >
            <template #tooltip>
              <q-tooltip>
                Ao confirmar, você impossibilitara o acesso a esta aula e não
                poderá recuperá-la.
              </q-tooltip>
            </template>
            <template v-slot:left-icon>
              <q-img :src="close" class="q-mr-sm" width="19px" />
            </template>
          </SecundaryButton>
        </div>
      </div>
      <div class="join-again" v-else>
        <h1 class="join-again-title">Opa, você saiu da aula</h1>
        <p class="join-info">{{ qtd_users }}</p>
        <q-img :src="womanWithLaptop" width="208px" height="226px" />
        <div class="join-again-actions">
          <PrimaryButton
            large
            label="Voltar para a aula"
            @onClick="join"
            width="184px"
          />
          <SecundaryButton
            large
            label="Encerrar aula"
            @onClick="endLesson"
            width="184px"
            v-if="canEndLesson"
          >
            <template v-slot:left-icon>
              <q-img :src="close" class="q-mr-sm" width="19px" />
            </template>
          </SecundaryButton>
        </div>
      </div>
    </div>
    <DialogShutUpLiveClass
      v-show="dialogsState.shutUpLiveClass"
      :showDialog="dialogsState.shutUpLiveClass"
      :endLesson="endLesson"
      @close="dialogsState.shutUpLiveClass = false"
    />

    <q-inner-loading :showing="dialogsState.loading">
      <div
        class="row justify-center q-my-md"
        style="height: auto; width: 40px; margin: auto"
      >
        <LoadingIcon />
      </div>
    </q-inner-loading>
  </section>
</template>

<script>
/* COMPONENTS */
import Mural from "@/components/Mural.vue";
import PrimaryButton from "@/components/liveClass/comumBtn/PrimaryButton.vue";
import SecundaryButton from "@/components/liveClass/comumBtn/SecundaryButton.vue";
import OnAndOffButton from "@/components/liveClass/comumBtn/OnAndOffButton.vue";
import Avatar from "@/components/liveClass/Avatar/Avatar.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
/* COMPOSABLES */
import useListPeripheals from "@/composables/useListPeripherals";
import useHandleErrorMedia from "@/composables/useHandleErrorMedia";

/* IMAGES */
import womanWithLaptop from "@/assets/images/womanWithLaptop.svg";
import close from "@/assets/icons/close.svg";

/* VUE */
import {
  computed,
  onMounted,
  reactive,
  ref,
  watch,
  defineAsyncComponent,
} from "vue";

/* VUEEX */
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";

import TrailDataService from "@/services/TrailDataServices";

import { usePermission } from "@vueuse/core";
import YAML from "js-yaml";

export default {
  name: "PreviewLive",
  components: {
    /* COMPONENTS */
    Mural,
    PrimaryButton,
    SecundaryButton,
    OnAndOffButton,
    Avatar,
    LoadingIcon,
    DialogShutUpLiveClass: defineAsyncComponent(() =>
      import("@/components/liveClass/Dialog/DialogShutUpLiveClass.vue")
    ),
  },
  setup() {
    /* CONSTS */
    const trailDataService = new TrailDataService();
    const store = new useStore();
    const route = new useRoute();
    const router = new useRouter();
    const $q = new useQuasar();

    const breadcrumbs = ref([`Nova aula ao vivo (${route.query.title})`]);
    const previewCamera = ref();
    const statePreview = reactive({
      isConnected: false,
      id: route.query.id,
      name: `${store.state.userdata.first_name} ${store.state.userdata.last_name}`,
      token: null,
      qtd: route.query.qtd,
    });

    const dialogsState = reactive({
      shutUpLiveClass: false,
      loading: false,
    });
    const peripheralsOnUse = reactive({
      camera: null,
      microphone: null,
      headphone: null,
    });

    const microphoneAccess = usePermission("microphone");
    const cameraAccess = usePermission("camera");

    const code = computed(() =>
      YAML.dump(
        reactive({
          microphoneAccess,
          cameraAccess,
        })
      )
    );

    let peripherals = reactive(useListPeripheals(peripheralsOnUse));

    const currentMediaOnUse = reactive({
      camera: false,
      microphone: false,
      headphone: false,
      screenCapture: false,
    });

    const currentStream = reactive({
      camera: null,
      microphone: null,
      headphone: null,
      screenCapture: null,
    });

    function getLocalStream() {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        })
        .catch((err) => {
          console.error(rr);
        });
    }

    function goBack() {
      router.push({
        name: "training-page",
        params: {
          courseId: route.query.courseId,
          trainingId: route.query.trainingId,
          postId: route.query.trainingId,
        },
      });
    }

    function handleSelectionCamera() {
      if (peripheralsOnUse.camera) {
        store.commit("setDeviceIdCamera", peripheralsOnUse.camera);
      }
    }

    function handleSelectionMicrophone() {
      if (peripheralsOnUse.microphone) {
        store.commit("setDeviceIdMicrophone", peripheralsOnUse.microphone);
        Microphone();
        Microphone();
      }
    }

    function handleSelectionHeadPhone() {
      if (peripheralsOnUse.headphone) {
        store.commit("setDeviceIdHeadphone", peripheralsOnUse.headphone);
        headphone(peripheralsOnUse.headphone);
      }
    }

    async function Camera() {
      if (currentMediaOnUse.camera) {
        currentStream.camera.getVideoTracks().forEach((track) => {
          track.stop();
        });

        currentMediaOnUse.camera = currentStream.camera.active;
        currentStream.camera = null;
        return;
      }

      await navigator.mediaDevices
        .getUserMedia({
          video: constraintsCamera.value,
        })
        .then((response) => {
          if (response) {
            currentMediaOnUse.camera = response.active;
            currentStream.camera = response;
            previewCamera.value.srcObject = currentStream.camera;
          }
        })
        .catch((error) => {
          useHandleErrorMedia("camera", error);
        });
    }

    function headphone(deviceId) {
      previewCamera.value
        .setSinkId(deviceId)
        .then((response) => {
          console.log(previewCamera.value.sinkId);
        })
        .catch((error) => {
          handleError("headphone", error);
        });
    }

    async function Microphone() {
      if (currentMediaOnUse.microphone) {
        currentStream.microphone.getAudioTracks().forEach((track) => {
          track.stop();
        });
        currentMediaOnUse.microphone = currentStream.microphone.active;
        currentStream.microphone = null;
        return;
      }

      await navigator.mediaDevices
        .getUserMedia({ audio: constraintsMicrophone.value })
        .then((response) => {
          if (response) {
            currentMediaOnUse.microphone = response.active;
            currentStream.microphone = response;
            previewCamera.value.srcObject = currentStream.microphone;
          }
        })
        .catch((error) => {
          useHandleErrorMedia("microphone", error);
        });
    }

    function joinMediaStream() {
      let videoTrack, audioTrack;

      if (currentMediaOnUse.camera && currentMediaOnUse.microphone) {
        [videoTrack] = currentStream.camera.getVideoTracks();
        [audioTrack] = currentStream.microphone.getAudioTracks();

        const newMediaStream = new MediaStream([videoTrack, audioTrack]);

        previewCamera.value.srcObject = newMediaStream;
      }
    }

    async function join() {
      dialogsState.loading = true;

      await trailDataService
        .getTokenLiveClass(statePreview)
        .then((response) => {
          if (response.data.length > 4) {
            statePreview.token = response.data;
            store.commit("setLiveData", {
              id: statePreview.id,
              name: statePreview.name,
              token: statePreview.token,
            });
            dialogsState.loading = false;
            router.push({
              name: "live",
            });
          }
        })
        .catch((error) => {
          $q.notify({
            message: `Esse erro pode ter sido causado por um problema com o sistema ou com a conta do usuário. <br/>
            Por favor, tente novamente mais tarde. Se o erro persistir, informe o seu administrador.`,
            type: "negative",
            html: true,
            caption: `${error?.message},<br/> ${error?.response?.data}`,
            position: "top",
          });
        });
    }

    async function endLesson() {
      await trailDataService
        .endLesson(statePreview.id)
        .then((response) => {
          if (response.data) {
            dialogsState.shutUpLiveClass = false;
            $q.notify({
              position: "top",
              type: "positive",
              message: "Aula encerrada com sucesso!",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    watch(
      () => [currentMediaOnUse.camera, currentMediaOnUse.microphone],
      () => {
        joinMediaStream();
      }
    );

    onMounted(() => {
      /*       getLocalStream(); */
    });

    const first_name = computed(() => store.state.userdata.first_name);
    const last_name = computed(() => store.state.userdata.last_name);
    const canEndLesson = computed(() => {
      if ([1, 2].includes(store.state.userdata.role_id)) {
        if (store.state.userdata.role_id === 1) {
          return true;
        }

        return Number(route.query.by) === store.state.userdata.id;
      }
      return false;
    });
    const fullName = computed(() => `${first_name.value} ${last_name.value}`);
    const constraintsMicrophone = computed(
      () => store.state.constraints.microphone
    );
    const constraintsHeadPhone = computed(
      () => store.state.constraints.headphone
    );
    const constraintsCamera = computed(() => store.state.constraints.camera);

    const qtd_users = computed(() => {
      if (statePreview.qtd_users === 1) {
        return `${statePreview.qtd} pessoa na aula`;
      }
      return `${statePreview.qtd} pessoas na aula`;
    });

    return {
      /* CONSTS */
      breadcrumbs,
      previewCamera,
      peripheralsOnUse,
      currentMediaOnUse,
      dialogsState,
      statePreview,
      route,
      /* COMPOSABLES */
      peripherals,
      /* IMAGES */
      womanWithLaptop,
      close,
      /* FUNCTIONS */
      goBack,
      Camera,
      Microphone,
      handleSelectionCamera,
      handleSelectionHeadPhone,
      handleSelectionMicrophone,
      join,
      endLesson,
      /* LIFECYCLES */
      first_name,
      last_name,
      canEndLesson,
      fullName,
      constraintsCamera,
      constraintsHeadPhone,
      constraintsMicrophone,
      qtd_users,
      code,
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  margin: 24px 24px 0 24px;
}

:is(.container-items, .enumerables-devices, .join-in) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-items {
  margin-top: 5dvh;
  gap: 16px;
}

.preview-item {
  display: flex;
  flex-direction: column;
}

.container-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  video {
    width: calc(100% - 16px);
    border-radius: 8px;
  }
  .video-off {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 541.5px;
    width: clamp(363px, calc(100% - 16px), calc(100% - 16px));
    background: var(--grayscale-grayscale-20, #f2f2f2);
    border-radius: 8px;
    border: 1px solid var(--grayscale-grayscale-40, #ccc);

    p {
      color: var(--grayscale-grayscale-70, #666);
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem; /* 150% */
      letter-spacing: -0.01563rem;
      margin-top: 16px;
      text-align: center;
    }
  }

  .container-on-off {
    width: 124px;
    display: flex;
    gap: 28px;
    position: relative;
    bottom: 70px;
  }

  .fullName {
    align-self: flex-start;
    position: relative;
    bottom: 65px;
    left: 15.5px;
    color: var(--grayscale-grayscale-10, #fafafa);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: -0.01563rem;
    &--on-off {
      color: var(--grayscale-grayscale-70, #666);
    }
  }
}

.join-in,
.join-again {
  font-family: Montserrat;
  margin: 16px 0;
  text-align: center;

  &-title {
    color: var(--grayscale-grayscale-75, #404040);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: -0.03125rem;
    margin-bottom: 16px;
  }
  &-info {
    color: var(--grayscale-grayscale-75, #404040);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: -0.01563rem;
    margin-bottom: 40px;
  }
  &-actions {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}

.enumerables-devices {
  gap: 16px;
  .device {
    display: flex;
    flex-direction: column;
    width: clamp(250px, 300px, 100%);
  }
}

@media (min-width: 1700px) {
  .container-items {
    flex-direction: row;
  }

  .enumerables-devices {
    flex-direction: row;
  }

  .preview-item {
    flex: 2;
  }

  .join-in,
  .join-again {
    flex: 1;
    margin: 0;
  }
}
</style>
