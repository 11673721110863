<template>
  <div class="container-avatar">
    <span>{{ avatarContent }}</span>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "Avatar",
  props: {
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
  },
  setup(props) {
    const avatarContent = computed(() => {
      if (props.firstName && props.lastName) {
        return `${props?.firstName[0]?.toLocaleUpperCase()}  ${props?.lastName[0]?.toLocaleUpperCase()}`;
      }
    });

    return {
      avatarContent,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background-color: var(--personal-light-personal-20-light, #ffccd1);
  text-align: center;
  span {
    color: var(--q-primary, #fe4e64);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 120% */
    letter-spacing: -0.03125rem;
  }
}
</style>
