import Notify from 'quasar/src/plugins/Notify.js';;

export default (mediaKind, err) => {
  function alertOf(message, caption) {
    Notify.setDefaults({
      position: "top",
      timeout: 10000,
      type: "negative",
      message: message,
      caption: `${mediaKind}: ${caption}`,
    });
    Notify.create(message, caption);
  }

  if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {
    //required track is missing
    console.error(err);
    alertOf(err.name, err.message);
  } else if (err.name == "NotReadableError" || err.name == "TrackStartError") {
    //webcam or mic are already in use
    console.error(err);
    alertOf(err.name, err.message);
  } else if (
    err.name == "OverconstrainedError" ||
    err.name == "ConstraintNotSatisfiedError"
  ) {
    //constraints can not be satisfied by avb. devices
    console.error(err);
    alertOf(err.name, err.message);
  } else if (
    err.name == "NotAllowedError" ||
    err.name == "PermissionDeniedError"
  ) {
    //permission denied in browser
    console.error(err);
    alertOf(err.name, err.message);
  } else if (err.name == "TypeError" || err.name == "TypeError") {
    //empty constraints object
    console.error(err);
    alertOf(err.name, err.message);
  } else {
    //other errors
    console.error(err);
    alertOf(err.name, err.message);
  }
};
